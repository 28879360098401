import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "time" ]

  connect() {
    // console.log("Hello, Add Story Clean Transcript here!", this.element)
    // console.log(this.modalTarget)
    // console.log(this.timeTarget)
  }

  displayModal(event) {
    if (event.target.dataset.confirmSwal) {
      // need to confirm first
      return;
    }

    this.timeTarget.innerText = event.params.time;

    const modal = this.modalTarget && new bootstrap.Modal(this.modalTarget);
    modal && modal.show();
  }

}
