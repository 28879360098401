import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "preview" ]

  connect() {
    // console.log("Hello, Entity Admin Quick Links Controller here!", this.element);
    // console.log("Preview target", this.previewTarget);
  }

  previewImage(event) {
    if (event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {

        // set the preview as the url
        this.previewTarget.src = e.target.result;
      };

      reader.readAsDataURL(event.detail.content.files[0]);
    }
  }

}
