import { Controller } from "@hotwired/stimulus"
import FuckingLightbox from '../packs/lightbox';

export default class extends Controller {
  static targets = [ "modalBody" ]

  connect() {
    // console.log("Hello, Deals Controller here!", this.element);
    // console.log(this.modalBodyTarget);
  }

  learnMore(event) {
    this.modalBodyTarget.innerHTML = "";

    fetch(`/deals/${event.params.dealId}`).then(res => res.text()).then(partial => {
      this.modalBodyTarget.innerHTML = partial;
      FuckingLightbox.reloadLightbox('glightbox-deal-images');
    });
  }

}
