import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    console.log("Hello, auto open modal Controller here!", this.element);

    const modal = new bootstrap.Modal(this.element);
    modal.show();
  }

}
