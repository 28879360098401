import { Controller } from "@hotwired/stimulus"

const titleized = {
  'industries': 'Industries',
  'activities': 'Activities'
}

export default class extends Controller {
  static targets = [ "industriesCheckbox", "industriesLabel", "activitiesCheckbox", "activitiesLabel" ]

  connect() {
    // console.log("Hello, Deals Edit here!", this.element);
    // console.log(this.industriesCheckboxTargets);
    // console.log(this.activitiesCheckboxTargets);

    this.activitiesLabelTarget.innerText = `Acitivites (${this.activitiesCheckboxTargets.filter(elm => elm.checked).length}/2)`;
    this.industriesLabelTarget.innerText = `Industries (${this.industriesCheckboxTargets.filter(elm => elm.checked).length}/2)`;
  }

  checkboxClicked(event) {

    let checkboxes, label;
    if (event.params.input == 'activities') {
      checkboxes = this.activitiesCheckboxTargets;
      label = this.activitiesLabelTarget;
    } else {
      checkboxes = this.industriesCheckboxTargets;
      label = this.industriesLabelTarget;
    }

    const count = checkboxes.filter(elm => elm.checked).length

    if ( count > 2) {
      event.target.checked = false;
      event.preventDefault();
    } else {
      label.innerText = `${titleized[event.params.input]} (${count}/2)`;
    }
  }

}
