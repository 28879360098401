// Shamelessly stolen from https://stackoverflow.com/a/50260127/1814019

/* example */
// var r1 = ratio(1600, 900);
// var r2 = ratio(1440, 900);
// var r3 = ratio(1366, 768);
// var r4 = ratio(1280, 1024);
// var r5 = ratio(1280, 720);
// var r6 = ratio(1024, 768);

/* will output this:
r1: [16, 9]
r2: [8, 5]
r3: [683, 384]
r4: [5, 4]
r5: [16, 9]
r6: [4, 3]
*/

/* the binary Great Common Divisor calculator */
function gcd (u, v) {
    if (u === v) return u;
    if (u === 0) return v;
    if (v === 0) return u;

    if (~u & 1)
        if (v & 1)
            return gcd(u >> 1, v);
        else
            return gcd(u >> 1, v >> 1) << 1;

    if (~v & 1) return gcd(u, v >> 1);

    if (u > v) return gcd((u - v) >> 1, v);

    return gcd((v - u) >> 1, u);
}

/* returns an array with the ratio */
const ratio = (w, h) => {
  console.log('Calculating ration for: ', w, h);
	var d = gcd(w,h);
	return [w/d, h/d];
}

module.exports = ratio;
