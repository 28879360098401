import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "results" ]

  connect() {
    // console.log("Hello, Scrape Job  Controller here!", this.element)
    // console.log(this.postTargets)
    // console.log(this.addButtonTarget)
    // console.log(this.containerTarget)
  }

  remove_athlete(event) {
    console.log('Removing athlete', event.target.dataset);
    const scrape_job_id = event.target.dataset.scrapeJobId

    let results = JSON.parse(this.resultsTarget.dataset.results);

    const [ removedAthlete ] = results.splice(parseInt(event.target.dataset.index), 1);

    fetch(`/scrape_job/${scrape_job_id}`, {
      method: 'POST',
      body: JSON.stringify({
          key: 'betterthanahotdogstand',
          results: results
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then( (event) => {

      this.addRemovedAthleteComment(scrape_job_id, removedAthlete)

      window.location.reload();
    }).catch( (error) => {
      console.log('ERROR', error);
    });
  }

  addRemovedAthleteComment(scrape_job_id, athlete) {
    const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    fetch(`/admin/comments.js`, {
      method: 'POST',
      body: JSON.stringify({
        'active_admin_comment': {
          namespace: 'admin',
          body: `Athlete Deleted: ${athlete["firstName"]} ${athlete["lastName"]}`,
          resource_type: 'ScrapeJob',
          resource_id: scrape_job_id
        }
      }),
      headers: {
        'X-CSRF-Token': token,
        'Content-type': 'application/json; charset=UTF-8',
      }
    }).then( (event) => {
      console.log('Successfully posted comment', event)
    }).catch( (error) => {
      console.log('ERROR', error);
    });
  }
}
