import { Controller } from "@hotwired/stimulus"
import { createAlert } from "../packs/createAlert";

let intervalId;

export default class extends Controller {
  static values = {
    url: String,
    trigger: String
  }

  connect() {
    // console.log("Hello, Stimulus from Stories Audio Transcript!", this.element)
    // console.log(this.urlValue)

    intervalId = setInterval( () => this.fetchTranscriptionJson(), 5000);
  }

  fetchTranscriptionJson() {
    try {
      fetch(this.urlValue).then(results => {
        if (results.status === 200) {

          // clear the interval so we aren't looking any more
          clearInterval(intervalId);

          if (!this.triggerValue || this.triggerValue === 'reload') {
            window.location.reload();
          }

          if (this.triggerValue === 'alert') {

            if(this.element.checkVisibility()) {
              // if the element is visible, reload the page
              window.location.reload();
            } else {
              // persistent notication that won't go away, letting the user know the transcription is complete
              createAlert('success', 'Transcription complete!', true);

              // mark the element as needing reloaded
              this.element.setAttribute('reloadContent', 'true');
            }

          }

        }
      });
    } catch (e) {
      console.error('Error when trying to fetch transcript: ', e);
    }
  }

}

