// src/controllers/interviewer_controller.js
import { Controller } from "@hotwired/stimulus"
import { createAlert } from '../packs/createAlert';
import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

// let audioBlob, audioElement, chunks = [];

let wavesurfer, record
let scrollingWaveform = true

export default class extends Controller {
  static targets = [
    "recordButton", "pauseButton", "resumeButton", "micSelect",
    "recordedAudioContainer", "waveFormContainer", "audioFile", "submitButton", "progress",
    "contentContainer", "errorContainer", "buttonContainer"]
  static values = {
    interval: Number,
    duration: String,
    bugReportPath: String
  }

  connect() {
    self.error = '';

    this.initAudio();
  }

  initAudio() {
    //check if browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording!');
      return;
    }

    this.createWaveSurfer();

    // This is attempting to get the user's microphone permissions
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then((stream) => {
        this.populateAvailableDevices();
      })
      .catch((err) => {
        console.error(`you got an error: ${err}`);
      });

    this.populateAvailableDevices();

  }

  populateAvailableDevices() {
    // empty the select
    this.micSelectTarget.innerHTML = '';

    // Mic selection
    RecordPlugin.getAvailableAudioDevices().then((devices) => {
      devices.forEach((device) => {
        const option = document.createElement('option')
        option.value = device.deviceId
        option.text = device.label || device.deviceId
        this.micSelectTarget.appendChild(option)
      })
    })
  }

  async record(event) {
    if (record.isRecording() || record.isPaused()) {
      record.stopRecording()
      this.recordButtonTarget.textContent = 'Re-record'
      // this.pauseButtonTarget.style.display = 'none'
      return
    }

    this.recordButtonTarget.disabled = true

    // reset the wavesurfer instance

    // get selected device
    const deviceId = this.micSelectTarget.value;
    record.startRecording({ deviceId: { exact: deviceId} }).then(() => {
      this.recordButtonTarget.textContent = 'Stop'
      this.recordButtonTarget.disabled = false
      // this.pauseButtonTarget.style.display = 'inline'

      // display the waveFormContainer
      this.waveFormContainerTarget.classList.remove('d-none');
      this.waveFormContainerTarget.classList.add('d-block');

      // hide the recordedAudioContainer
      this.recordedAudioContainerTarget.classList.remove('d-block');
      this.recordedAudioContainerTarget.classList.add('d-none');
    })

  }

  // resume() {
  //   //check if there are any previous recordings and remove them
  //   if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
  //     this.audioContainerTarget.firstElementChild.remove();
  //   }

  //   // hide the resume button
  //   this.resumeButtonTarget.classList.remove(('d-block'))
  //   this.resumeButtonTarget.classList.add(('d-none'))

  //   // hide the resume button
  //   this.recordButtonTarget.classList.remove(('d-block'))
  //   this.recordButtonTarget.classList.add(('d-none'))

  //   // show the pause button
  //   this.pauseButtonTarget.classList.remove(('d-none'))
  //   this.pauseButtonTarget.classList.add(('d-block'))

  //   // start recording
  //   mediaRecorder.resume();
  //   this.startInternalRecordingTimer(parseInt(this.durationValue));
  // }

  // This method is actually triggered from a button that is labeled "Stop"
  // because that is what users will probably understand it as. But if we
  // used the mediaRecorder.stop() method, it would set the state on the
  // recorder to "inactive" and it couldn't be restarted. So we pause it
  // instead technically, and tell the user they are stopping it.
  // async pause() {
  //   // console.log('Pausing recording');

  //   //check if there are any previous recordings and remove them
  //   if (this.audioContainerTarget.firstElementChild?.tagName === 'AUDIO') {
  //     this.audioContainerTarget.firstElementChild.remove();
  //   }

  //   // pause recording
  //   mediaRecorder.pause();
  //   clearInterval(this.intervalValue);

  //   // hide the pause button
  //   this.pauseButtonTarget.classList.remove(('d-block'))
  //   this.pauseButtonTarget.classList.add(('d-none'))

  //   // show the resume recording button
  //   this.resumeButtonTarget.classList.remove(('d-none'))
  //   this.resumeButtonTarget.classList.add(('d-block'))

  //   // show the re-record button
  //   this.recordButtonTarget.classList.remove('d-none')
  //   this.recordButtonTarget.classList.add('d-block')
  //   this.recordButtonTarget.innerHTML = "<i class='fas fa-microphone me-1'></i> Record again"
  //   this.recordButtonTarget.dataset.confirmSwal = "Are you sure?"
  //   this.recordButtonTarget.dataset.text = "Re-recording will replace your previously recorded answer."
  // }



  attemptSubmit(event) {
    if(
      this.audioFileTarget.files.length === 0 &&
      this.audioContainerTarget?.firstElementChild?.tagName !== 'AUDIO'
    ) {
      event.preventDefault();
      createAlert('info', 'Please record an audio answer before moving forward.')
    }
  }

  sendBugReport() {
    const bugReport = {
      origin: window.location.href,
      error: this.error.toString(),
      cookiesEnabled: navigator.cookieEnabled,
      language: navigator.language,
      userAgent: navigator.userAgent,
      navigatorUAData: navigator.userAgentData,
      dimensions: { width: window.innerWidth, height: window.innerHeight }
    };

    fetch(this.bugReportPathValue, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bugReport)
    });

    createAlert('info', 'Bug Report Sent!');
  }

  createWaveSurfer() {
    // Create an instance of WaveSurfer
    if (wavesurfer) {
      wavesurfer.destroy()
    }
    wavesurfer = WaveSurfer.create({
      container: '#waveFormContainer',
      waveColor: '#0e1a2c',
      progressColor: '#FFD81B',
      // Set a bar width
      barWidth: 2,
      // Optionally, specify the spacing between bars
      barGap: 1,
      // And the bar radius
      barRadius: 2
    })

    // Initialize the Record plugin
    record = wavesurfer.registerPlugin(RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: false }))
    // Render recorded audio
    record.on('record-end', (blob) => {
      this.recordedAudioContainerTarget.innerHTML = '';

      // const container = document.querySelector('#recordings')
      const recordedUrl = URL.createObjectURL(blob)

      // Create wavesurfer from the recorded audio
      const wavesurfer = WaveSurfer.create({
        container: '#recordedAudioContainer',
        waveColor: '#0e1a2c',
        progressColor: '#FFD81B',
        url: recordedUrl,
        // Set a bar width
        barWidth: 2,
        // Optionally, specify the spacing between bars
        barGap: 1,
        // And the bar radius
        barRadius: 2
      })

      // hide the waveFormContainer
      this.waveFormContainerTarget.classList.remove('d-block');
      this.waveFormContainerTarget.classList.add('d-none');

      // show recordedAudioContainer
      this.recordedAudioContainerTarget.classList.remove('d-none');
      this.recordedAudioContainerTarget.classList.add('d-block');

      // Play button
      const button = this.recordedAudioContainerTarget.appendChild(document.createElement('button'))
      button.innerHTML = '<i class="fas fa-play"></i> Play'
      button.type = 'button'
      button.classList.add('btn', 'mt-3', 'bg-fanword-background')
      button.onclick = () => wavesurfer.playPause()
      wavesurfer.on('pause', () => (button.innerHTML = '<i class="fas fa-play"></i> Play'))
      wavesurfer.on('play', () => (button.innerHTML = '<i class="fas fa-pause"></i> Pause'))

      // submit button
      const submitButton = this.recordedAudioContainerTarget.appendChild(document.createElement('button'))
      submitButton.innerHTML = 'Submit'
      submitButton.classList.add('btn', 'ms-3', 'mt-3', 'bg-fanword-yellow')

      // file attached to the hidden input field
      let file = new File([blob], "audio-interview.wav", {
        lastModified: new Date().getTime(),
      });
      let container = new DataTransfer();
      container.items.add(file);
      this.audioFileTarget.files = container.files;
    })

    this.recordButtonTarget.innerHTML = '<i class="fas fa-microphone me-1 color-fanword-green"></i> Record'

    record.on('record-progress', (time) => {
      this.updateProgress(time)
    })
  }

  updateProgress(time) {
    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':')
    this.progressTarget.textContent = formattedTime
  }


}
