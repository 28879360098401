import CookiesEuBanner from 'cookies-eu-banner'
// import 'cookies-eu-banner/css/cookies-eu-banner.default.css'

document.addEventListener('DOMContentLoaded', () => {
  // console.log('Cookie banner intialized');

  document.querySelector('#cookies-eu-banner') && new CookiesEuBanner(() => {
    // console.log('Cookies EU Banner accepted');

    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', window.GOOGLE_ANALYTICS);
  }, false)
})
