import { Controller } from "@hotwired/stimulus"

const OPTION_TO_LABEL = {
  "-1": "Sport",
  "-2": "Sport",
  "-3": "Sport",
  "-4": "Department"
}

export default class extends Controller {
  static targets = [ "select", "detailContainer", "detailInput" ]
  static values = {
    details: Object
  }

  connect() {
    // console.log("Hello, Story AI Subjects Controller here!", this.element);
    // console.log(this.selectTarget);
    // console.log(this.detailsValue);

    if (this.selectTarget.value) {
      this.updateDetail({ target: { value: this.selectTarget.value } });
    }
  }

  async updateDetail(event) {
    var label;
    if (Object.keys(this.detailsValue).length > 0) {
      label = this.detailsValue[event.target.value];
    } else {
      label = OPTION_TO_LABEL[event.target.value];
    }

    if (!label) {
      this.detailContainerTarget.classList.remove("d-block");
      this.detailContainerTarget.classList.add("d-none");

      this.detailInputTarget.value = "";

      return;
    }

    this.detailContainerTarget.querySelector("label").innerText = label;

    this.detailContainerTarget.classList.remove("d-none");
    this.detailContainerTarget.classList.add("d-block");
  }

}
