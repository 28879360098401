import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "form", "completeDialog" ]

  onComplete() {
    // console.log('Got here!', event);
    this.formTarget.classList.remove('d-block');
    this.formTarget.classList.add('d-none');

    this.completeDialogTarget.classList.remove('d-none');
    this.completeDialogTarget.classList.add('d-block');
  }

}

