// src/controllers/interviewer_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "progressStep", "disclosurePage", "agentFields", "agentName", "activityDetailQuestion", "businessName", "activityProof", "compensationPercentage", "compensationValue" ]

  static values = {
    currentStep: Number
  }

  connect() {
    // console.log("Hello, Stimulus!", this.element)
    // console.log(this.disclosurePageTargets)
    // console.log(this.agentNameTarget);
  }

  updateActivityFollowupQuestion(event) {
    const activity = event.target.value;

    // set all follow up questions to d-none
    this.activityDetailQuestionTargets.forEach(elm => {
      elm.classList.remove('d-block');
      elm.classList.add('d-none');
      elm.querySelector('select').removeAttribute('required');
    })

    // find desired question and show it
    const activityFollowUpQuestion = this.activityDetailQuestionTargets.find(elm => elm.id === activity)

    activityFollowUpQuestion.classList.remove('d-none');
    activityFollowUpQuestion.classList.add('d-block');
    activityFollowUpQuestion.querySelector('select').setAttribute('required', true);
  }

  updateAgentQuestions(event) {
    const checked = event.target.checked;

    if (checked === true) {
      this.agentFieldsTarget.classList.remove('d-none');
      this.agentFieldsTarget.classList.add('d-flex');
      this.agentNameTarget.setAttribute('required', true);
    } else {
      this.agentFieldsTarget.classList.remove('d-flex');
      this.agentFieldsTarget.classList.add('d-none');
      this.agentNameTarget.setAttribute('required', false);
    }
  }

  updateCompensatorFields(event) {
    const compensator = event.target.value;

    if (compensator === 'business') {
      this.businessNameTarget.classList.remove('d-none');
      this.businessNameTarget.classList.add('d-block');
      this.businessNameTarget.querySelector('input').setAttribute('required', true);
    } else {
      this.businessNameTarget.classList.remove('d-block');
      this.businessNameTarget.classList.add('d-none');
      this.businessNameTarget.querySelector('input').removeAttribute('required');
    }
  }

  updateProofField(event) {
    const occurAlready = event.target.value;

    if (occurAlready === 'yes') {
      this.activityProofTarget.classList.remove('d-none');
      this.activityProofTarget.classList.add('d-block');
      this.activityProofTarget.querySelector('input').setAttribute('required', true);
    } else {
      this.activityProofTarget.classList.remove('d-block');
      this.activityProofTarget.classList.add('d-none');
      this.activityProofTarget.querySelector('input').removeAttribute('required');
    }
  }

  updateCompensationFields(event) {
    const compensation = event.target.value;

    this.compensationValueTarget.classList.remove('d-block');
    this.compensationValueTarget.classList.add('d-none');
    this.compensationValueTarget.querySelector('input').removeAttribute('required');

    this.compensationPercentageTarget.classList.remove('d-block');
    this.compensationPercentageTarget.classList.add('d-none');
    this.compensationPercentageTarget.querySelector('input').removeAttribute('required');

    if (compensation === 'cash/cash_equivalent' || compensation === 'product/service') {
      this.compensationValueTarget.classList.remove('d-none');
      this.compensationValueTarget.classList.add('d-block');
      this.compensationValueTarget.querySelector('input').setAttribute('required', true);
    } else {
      this.compensationPercentageTarget.classList.remove('d-none');
      this.compensationPercentageTarget.classList.add('d-block');
      this.compensationPercentageTarget.querySelector('input').setAttribute('required', true);
    }
  }

  changeStep(event) {

    // when moving forward in the form, run validation checks
    if (event.params.targetStep > this.currentStepValue) {
      let requiredPageElements = Array.from(this.disclosurePageTargets[this.currentStepValue - 1].querySelectorAll('[required], [type="email"]'));
      if (!requiredPageElements.every(elm => elm.checkValidity())) {
        console.log('Validity checks failed!');
        requiredPageElements.every(elm => elm.reportValidity());
        return;
      }
    }

    // update the progress indicator
    this.progressStepTargets.forEach(elm => elm.classList.remove('active'));
    this.progressStepTargets[event.params.targetStep - 1].classList.add('active')

    // update the UI
    this.disclosurePageTargets.forEach(elm => { elm.classList.remove('d-block'); elm.classList.add('d-none'); });
    this.disclosurePageTargets[event.params.targetStep - 1].classList.remove('d-none');
    this.disclosurePageTargets[event.params.targetStep - 1].classList.add('d-block');

    this.currentStepValue = event.params.targetStep;
  }

}

