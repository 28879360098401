import { Controller } from "@hotwired/stimulus"
import { fabric } from 'fabric'

export default class extends Controller {
  static targets = [
    "templateSelect", "ratioSelect", "colorButton", "gradientButton",
    "color1", "color1Container", "color2", "color2Container", "gradientContainer", "gradientStyle", "angleSelect", "media1",
    "media2", "media3", "filterSelect", "transparency", "addHeader",
    "addSubheader", "addParagraph", "fontSelect", "styleSelect",
    "fontAlign",
    "textColor", "textBackgroundColor", "download", "saveTemplate"
  ]

  connect() {
    this.canvas = new fabric.Canvas('c')
    this.canvasScale = 1
    this.canvas.setWidth(document.getElementById('parent').clientWidth)
    this.canvas.setHeight(document.getElementById('parent').clientWidth)

    this.setupEventListeners()
  }

  setupEventListeners() {
    this.templateSelectTarget.addEventListener('change', this.loadTemplate.bind(this))
    this.ratioSelectTargets.forEach(elm => elm.addEventListener('click', this.changeRatio.bind(this)) )
    this.colorButtonTarget.addEventListener('click', this.setColor.bind(this))
    this.gradientButtonTarget.addEventListener('click', this.setGradient.bind(this))
    this.color1Target.addEventListener('input', this.updateColor.bind(this))
    this.color2Target.addEventListener('input', this.updateGradient.bind(this))
    this.gradientStyleTarget.addEventListener('change', this.updateGradientStyle.bind(this))
    this.angleSelectTarget.addEventListener('change', this.updateAngle.bind(this))
    this.media1Target.addEventListener('change', this.handleImageUpload.bind(this))
    this.media2Target.addEventListener('change', this.handleImageUpload.bind(this))
    this.media3Target.addEventListener('change', this.handleImageUpload.bind(this))
    this.filterSelectTarget.addEventListener('change', this.applyFilter.bind(this))
    this.transparencyTarget.addEventListener('input', this.updateTransparency.bind(this))
    this.addHeaderTarget.addEventListener('click', this.addHeader.bind(this))
    this.addSubheaderTarget.addEventListener('click', this.addSubheader.bind(this))
    this.addParagraphTarget.addEventListener('click', this.addParagraph.bind(this))
    this.fontSelectTarget.addEventListener('change', this.updateFont.bind(this))
    this.styleSelectTarget.addEventListener('change', this.updateStyle.bind(this))
    this.fontAlignTarget.addEventListener('change', this.updateFontAlign.bind(this))
    this.textColorTarget.addEventListener('input', this.updateTextColor.bind(this))
    this.textBackgroundColorTarget.addEventListener('input', this.updateTextBackgroundColor.bind(this))
    this.downloadTarget.addEventListener('click', this.downloadCanvas.bind(this))
    this.saveTemplateTarget.addEventListener('click', this.saveTemplate.bind(this))

    document.addEventListener('keydown', this.deleteActiveObject.bind(this))
    this.canvas.on('selection:cleared', this.clearSelection.bind(this))
  }

  loadTemplate() {
    var selectedTemplateId = this.templateSelectTarget.value;
    if (selectedTemplateId) {
      fetch(`/templates/${selectedTemplateId}.json`)
        .then(response => response.json())
        .then(data => {
          this.canvas.loadFromJSON(data, this.canvas.renderAll.bind(this.canvas));
      });
    } else {
      this.canvas.clear();
    }
  }

  changeRatio(event) {
    this.setCanvasSize(event.target.value);

    this.ratioSelectTargets.forEach(elm => {
      if (elm === event.target) {
        elm.classList.add('bg-fanword-background');
      } else {
        elm.classList.remove('bg-fanword-background');
      }
    });
  }

  setCanvasSize(ratio) {
    var containerWidth = document.getElementById('parent').clientWidth;
    var scaleWidth = 1;
    var scaleFactor = 1;
    var desiredWidth = ratio.split(':')[0];
    var desiredHeight = ratio.split(':')[1];

    scaleWidth = containerWidth / desiredWidth;
    scaleFactor = scaleWidth / this.canvasScale;
    this.canvas.setWidth(desiredWidth * scaleWidth);
    this.canvas.setHeight(desiredHeight * scaleWidth);
    this.canvasScale = scaleWidth;

    this.scaleObjects(scaleFactor);
    this.canvas.renderAll();

    // if (ratio === '800:800') {
    //   scaleWidth = containerWidth / 800;
    //   scaleFactor = scaleWidth / this.canvasScale;
    //   this.canvas.setWidth(636);
    //   this.canvas.setHeight(636);
    //   this.canvasScale = scaleWidth;
    // } else if (ratio === '1280:720') {
    //   scaleWidth = containerWidth / 1280;
    //   scaleFactor = scaleWidth / this.canvasScale;
    //   this.canvas.setWidth(636);
    //   this.canvas.setHeight(357);
    //   this.canvasScale = scaleWidth;
    // } else if (ratio === '720:1280') {
    //   scaleWidth = containerWidth / 720;
    //   scaleFactor = scaleWidth / this.canvasScale;
    //   this.canvas.setWidth(636);
    //   this.canvas.setHeight(1130);
    //   this.canvasScale = scaleWidth;
    // }

    // this.scaleObjects(scaleFactor);
    // this.canvas.renderAll();
  }

  setColor(event) {
    document.querySelectorAll('.background-buttons').forEach(elm => {
      if(elm == event.target) {
        elm.classList.add('bg-fanword-background');
      } else {
        elm.classList.remove('bg-fanword-background');
      }
    })

    // enable color1 picker
    this.color1ContainerTarget.classList.remove('d-none');

    // disable color2 picker
    this.color2ContainerTarget.classList.add('d-none');
    this.gradientContainerTarget.classList.add('d-none');

    var color = this.color1Target.value;
    this.canvas.setBackgroundColor(color, this.canvas.renderAll.bind(this.canvas));
  }

  setGradient(event) {
    document.querySelectorAll('.background-buttons').forEach(elm => {
      if(elm == event.target) {
        elm.classList.add('bg-fanword-background');
      } else {
        elm.classList.remove('bg-fanword-background');
      }
    })

    // enable color2 picker
    this.color2ContainerTarget.classList.remove('d-none');

    // enable gradient options
    this.gradientContainerTarget.classList.remove('d-none');

    this.updateBackgroundGradient();
  }

  updateColor(event) {
    document.querySelectorAll('.background-buttons').forEach(elm => {
      if(elm == event.target) {
        elm.classList.add('bg-fanword-background');
      } else {
        elm.classList.remove('bg-fanword-background');
      }
    })

    var color = this.color1Target.value;
    this.canvas.setBackgroundColor(color, this.canvas.renderAll.bind(this.canvas));
  }

  updateGradient() {
    this.updateBackgroundGradient();
  }

  updateGradientStyle() {
    var style = this.gradientStyleTarget.value;
    this.angleSelectTarget.disabled = style === "radial";
    this.updateBackgroundGradient();
  }

  updateAngle() {
    this.updateBackgroundGradient();
  }

  updateBackgroundGradient() {
    var style = this.gradientStyleTarget.value;
    var angle = parseInt(this.angleSelectTarget.value);
    var radians = angle * Math.PI / 180;

    var x2 = Math.cos(radians) * this.canvas.width;
    var y2 = Math.sin(radians) * this.canvas.height;

    var color1 = this.color1Target.value;
    var color2 = this.color2Target.value;

    var type;
    var gradientCoords;
    if (style === "linear") {
        type = 'linear';
        gradientCoords = {
            x1: 0,
            y1: 0,
            x2: x2,
            y2: y2
        };
    } else if (style === "radial") {
        type = 'radial';
        gradientCoords = {
          x1: this.canvas.width / 2,
          y1: this.canvas.height / 2,
          r1: 0,
          x2: this.canvas.width / 2,
          y2: this.canvas.height / 2,
          r2: Math.max(this.canvas.width, this.canvas.height) / 2
        };
    }

    var gradient = new fabric.Gradient({
        type: type,
        coords: gradientCoords,
        colorStops: [
            { offset: 0, color: color1 },
            { offset: 1, color: color2 }
        ]
    });

    this.canvas.setBackgroundColor(gradient, this.canvas.renderAll.bind(this.canvas));
  }

  handleImageUpload(e) {
    console.log(e);
    var fileInput = e.target;
    var reader = new FileReader();
    reader.onload = (event) => {
      console.log('event');
      var imgObj = new Image();
      imgObj.src = event.target.result;
      imgObj.onload = () => {
        var image = new fabric.Image(imgObj);
        const canvasCenter = this.canvas.getCenter();
        image.set({left: canvasCenter.left, top: canvasCenter.top, originX: 'center', originY: 'center'})
        // image.scaleToWidth(document.getElementById('parent').clientWidth);
        this.canvas.add(image);
        this.canvas.bringToFront(image);
        this.canvas.setActiveObject(image);
        this.displayUploadedImages(event.target.result, image, fileInput);
      }
    }
    reader.readAsDataURL(e.target.files[0]);
  }

  displayUploadedImages(imageSrc, fabricImage, fileInput) {
    var imgAreaSelector = fileInput.dataset.imgArea
    var imgArea = document.querySelector(`#${imgAreaSelector}`);
    var imgDiv = document.createElement('div');
    imgDiv.classList.add('uploaded-image');

    var imgElement = document.createElement('img');
    imgElement.src = imageSrc;
    imgElement.classList.add('uploaded-img');
    imgElement.style.maxWidth = '50px';

    var deleteContainer = document.createElement('span');
    deleteContainer.innerHTML = '<i class="fas fa-trash-alt color-fanword-black"></i>';
    deleteContainer.classList.add('ms-2', 'cursor-fanword-pointer');

    deleteContainer.onclick = () => {
      imgDiv.remove();
      this.canvas.remove(fabricImage);
      this.canvas.renderAll();
      fileInput.value = "";
    };

    imgDiv.appendChild(imgElement);
    imgDiv.appendChild(deleteContainer);
    imgArea.appendChild(imgDiv);
  }

  applyFilter() {
    var filter = this.filterSelectTarget.value;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'image') {
      activeObject.filters = [];
      if (filter === 'grayscale') {
        activeObject.filters.push(new fabric.Image.filters.Grayscale());
      } else if (filter === 'sepia') {
        activeObject.filters.push(new fabric.Image.filters.Sepia());
      } else if (filter === 'none') {
        activeObject.filters = [];
      }
      activeObject.applyFilters();
      this.canvas.renderAll();
    }
  }

  updateTransparency() {
    var transparency = this.transparencyTarget.value / 100;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject) {
      activeObject.set('opacity', transparency);
      this.canvas.renderAll();
    }
  }

  addHeader() {
    this.addText('header');
    this.addSubheaderTarget.classList.remove('active');
    this.addParagraphTarget.classList.remove('active');
    this.addHeaderTarget.classList.add('active');
  }

  addSubheader() {
    this.addText('subheader');
    this.addHeaderTarget.classList.remove('active');
    this.addParagraphTarget.classList.remove('active');
    this.addSubheaderTarget.classList.add('active');
  }

  addParagraph() {
    this.addText('paragraph');
    this.addHeaderTarget.classList.remove('active');
    this.addSubheaderTarget.classList.remove('active');
    this.addParagraphTarget.classList.add('active');
  }

  addText(type) {
    var text;
    const canvasCenter = this.canvas.getCenter();
    if (type === 'header') {
      text = new fabric.Textbox("Header", { fontSize: 80, left: canvasCenter.left, top: canvasCenter.top, originX: 'center', originY: 'center' });
    } else if (type === 'subheader') {
      text = new fabric.Textbox("Subheader", { fontSize: 56, left: canvasCenter.left, top: canvasCenter.top, originX: 'center', originY: 'center' });
    } else if (type === 'paragraph') {
      text = new fabric.Textbox("Paragraph", { fontSize: 32, left: canvasCenter.left, top: canvasCenter.top, originX: 'center', originY: 'center' });
    }
    this.canvas.add(text);
    this.canvas.bringToFront(text);
    this.canvas.setActiveObject(text);
  }

  updateFont() {
    var font = this.fontSelectTarget.value;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('fontFamily', font);
      this.canvas.renderAll();
    }
  }

  updateStyle() {
    var style = this.styleSelectTarget.value;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('fontStyle', style);
      this.canvas.renderAll();
    }
  }

  updateFontAlign() {
    const align = this.fontAlignTarget.value;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('textAlign', align);
      this.canvas.renderAll();
    }
  }

  updateTextColor() {
    var color = this.textColorTarget.value;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('fill', color);
      this.canvas.renderAll();
    }
  }

  updateTextBackgroundColor() {
    var color = this.textBackgroundColorTarget.value;
    var activeObject = this.canvas.getActiveObject();
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set('backgroundColor', color);
      this.canvas.renderAll();
    }
  }

  downloadCanvas() {
    var scaleFactor = 1 / this.canvasScale;
    this.canvas.setWidth(this.canvas.width * scaleFactor);
    this.canvas.setHeight(this.canvas.height * scaleFactor);
    this.scaleObjects(scaleFactor);
    this.canvas.renderAll();

    var dataURL = this.canvas.toDataURL({ format: 'png' });
    var link = document.createElement('a');
    link.href = dataURL;
    link.download = 'canvas.png';
    link.click();

    this.canvas.setWidth(this.canvas.width * this.canvasScale);
    this.canvas.setHeight(this.canvas.height * this.canvasScale);
    this.scaleObjects(this.canvasScale);
    this.canvas.renderAll();
  }

  scaleObjects(scaleFactor) {
    var objects = this.canvas.getObjects();

    for (var i in objects) {
      var scaleX = objects[i].scaleX;
      var scaleY = objects[i].scaleY;
      var left = objects[i].left;
      var top = objects[i].top;

      var tempScaleX = scaleX * scaleFactor;
      var tempScaleY = scaleY * scaleFactor;
      var tempLeft = left * scaleFactor;
      var tempTop = top * scaleFactor;

      objects[i].scaleX = tempScaleX;
      objects[i].scaleY = tempScaleY;
      objects[i].left = tempLeft;
      objects[i].top = tempTop;

      objects[i].setCoords();
    }
  }

  saveTemplate() {
    var jsonData = JSON.stringify(this.canvas.toJSON());
    var blob = new Blob([jsonData], { type: "application/json" });
    var url = URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    link.download = 'canvas.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  deleteActiveObject(e) {
    const activeObject = this.canvas.getActiveObject();

    if (!activeObject) {
      return;
    }

    if (activeObject.type == 'textbox' && activeObject.text !== '') {
      // do not remove the object if we're just backspacing a character from a text field
      return;
    }

    if (e.key === 'Delete' || e.key === 'Backspace') {
      this.canvas.remove(activeObject);

      // if this is an image, we need to remove the corresponding uploaded image
      if (activeObject.type === 'image') {
        var uploadedImages = document.querySelectorAll('.uploaded-image');
        uploadedImages.forEach(img => {
          if (img.querySelector('img').src === activeObject._element.src) {
            img.remove();
          }
        });
      }

      this.canvas.renderAll();
    }
  }

  clearSelection() {
    var filter = this.filterSelectTarget;
    var transparency = this.transparencyTarget;
    var fontSelect = this.fontSelectTarget;
    var styleSelect = this.styleSelectTarget;
    filter.value = '';
    transparency.value = '';
    fontSelect.value = '';
    styleSelect.value = '';
  }
}