import { Controller } from "@hotwired/stimulus"
import 'select2';

var selected_data = {};

export default class extends Controller {
  static targets = [ "type", "id", "entityAdmins" ]
  static values = {
    endpoint: String
  }

  connect() {
    // console.log("Hello, Admin Partnerable Controller here!", this.element);
    // console.log(this.idTarget);
    // console.log(this.typeTarget);

    selected_data = { id: this.idTarget.dataset['selectedId'], text: this.idTarget.dataset['selectedText'] };

    this.triggerTypeChange();
  }

  updateIds(event) {
    var $value = event.target.value;

    if (this.idTarget.classList.contains("select2-hidden-accessible")) {
        this.idTarget.value = null
        this.triggerIdChange();
        $(this.idTarget).select2('destroy');
    }

    $(this.idTarget).select2({
        data: [selected_data],
        ajax: {
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            url: `/admin/${this.endpointValue}/polymorphic_searchable`,
            data: function (params) {
                return {
                    searchable_type: $value,
                    search: params.term
                };
            }
        }
    });

    if (this.hasEntityAdminsTarget) {
      $(this.idTarget).on('change', () => {
        this.updateEntityAdmins();
      });
    }

    if (selected_data.id) {
        this.idTarget.value = selected_data.id;
        this.triggerIdChange();
        selected_data = {};
    }
  }

  triggerIdChange() {
    console.log('Dispatching change event on idTarget');
    this.idTarget.dispatchEvent(new Event("change"));
  }

  triggerTypeChange() {
    this.typeTarget.dispatchEvent(new Event("change"));
  }

  updateEntityAdmins() {
    // debugger;

    if (!this.typeTarget.value || !this.idTarget.value) {
      return;
    }

    // debugger;

    // console.log('updating entity admins');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    });

    fetch(`/admin/entity_admins/polymorphic_searchable?searchable_type=${this.typeTarget.value}&searchable_id=${this.idTarget.value}`, {
      method: 'GET',
      headers: headers
    })
    .then(response => response.json())
    .then(data => {
      // console.log('Got data from entity admin look up');
      // console.log(data);

      this.entityAdminsTarget.innerHTML = '';

      data.results.forEach(admin => {
        this.entityAdminsTarget.add(new Option(`${admin.first_name} ${admin.last_name}`, admin.id));
      })
    });
  }

}
