import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "button" ]

  connect() {
    // console.log("Hello, Add Story Subject Send Controller here!", this.element)
    // console.log(this.inputTarget)
    // console.log(this.buttonTarget)

    if (this.inputTarget.value !== '') {
      this.buttonTarget.removeAttribute('disabled');
    } else {
      this.buttonTarget.setAttribute('disabled', 'disabled');
    }
  }

  updateDisabledStatus(event) {
    if (event.target.value !== '') {
      this.buttonTarget.removeAttribute('disabled');
    } else {
      this.buttonTarget.setAttribute('disabled', 'disabled');
    }
  }

}
