import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    // console.log("Hello, Add Link Controller here!", this.element)
  }

  updateSeason(event) {
    const season = event.target.value;
    const url = new URL(window.location.href);
    url.searchParams.set('season', season);
    window.location = url;
  }

}
