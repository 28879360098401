import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { createAlert } from '../packs/createAlert';

export default class extends Controller {
  static targets = [ 'sortable', 'block', 'input' ]
  static values = { url: String }

  connect() {
    // console.log("Hello, Game Recap Layout Controller here!", this.element);

    const that = this;

    // re-order the blocks on the page so that the active ones are floated to the top
    const activeBlocks = this.blockTargets.filter(elm => elm.querySelector('input[type="checkbox"]').checked);

    const sortedActiveBlocks = activeBlocks.sort(function(a, b) {
      return +a.dataset.order - +b.dataset.order;
    });

    // find where they need to be inserted at
    const firstNonActiveBlock = this.blockTargets.find(elm => !elm.querySelector('input[type="checkbox"]').checked);

    sortedActiveBlocks.forEach(elm => {
      this.sortableTarget.insertBefore(elm, firstNonActiveBlock)
    });

    /* Sortable */
    this.sortableTargets.forEach(elm => {
      Sortable.create(elm, {
        draggable: '.layout-block',
        dataIdAttr: 'data-sortable-id',
        handle: ".sortable-handle",  // Drag handle selector within list items

        // Changed sorting within list
        onUpdate: (event) => {
          // console.log('event', event);
         that.updateActivationAndOrder();
        }
      });
    });

    $('body').on('mouseover', '.sortable .list-group-item', function() {
      $(this).find('.sortable-handle').removeClass('invisible').addClass('visible');
    }).on('mouseout', '.list-group-item', () => {
      $(this).find('.sortable-handle').removeClass('visible').addClass('invisible');
    });

    this.inputTargets.forEach(elm => elm.addEventListener('click', this.updateActivationAndOrder.bind(this)));
  }

  updateActivationAndOrder() {
    // console.log('Updating activation and order');

    const blocks = this.blockTargets;

    // get all of the checked boxes
    const checkedBoxes = Array.from(blocks).filter((elm) => elm.querySelector('input[type="checkbox"]').checked);
    const sortableIds = checkedBoxes.map(elm => elm.dataset.sortableId)

    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }

    var token;
    if (document.querySelector('meta[name="csrf-token"]')) {
      token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

      headers['X-CSRF-Token'] = token;
    }

    fetch(this.urlValue, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ rails_sortable: sortableIds })
    }).then( () => {
      createAlert('success', 'Layout updated successfully');
    })
  }


}
