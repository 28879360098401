import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "page" ]

  connect() {
    // console.log("Hello, Help Panel Controller here!", this.element)
    // console.log(this.pageTargets)

    // Listen for tabs to change and retrigger tour logic
    var tabs = document.querySelectorAll('button[data-bs-toggle="tab"][data-help-page]')
    tabs.forEach(elm => {
      elm.addEventListener('shown.bs.tab', (event) => {

        const target = event.target.dataset.helpPage || event.target.dataset.bsTarget;

        // console.log('Changing tab and updating help page', target);
        this.displayHelpPage(target); // #subject or #responses

        this.updateHelpBody(event.target.dataset.tourKey);
      });
    });

    // open up the active tab's help page on load
    const activeTab = Array.from(tabs).find(elm => elm.classList.contains('active'));
    const target = activeTab.dataset.helpPage || activeTab.dataset.bsTarget;
    // console.log('setting detaul help panel', target);
    this.displayHelpPage(target);
  }

  openHelp({ params }) {
    // console.log('Opening help!', params.slug);
    const help = document.querySelector(`#${params.slug}-help-offcanvas`);
    help && help.classList.add('open');
  }

  closeHelp({ params }) {
    // console.log('Closing help!', params.slug);
    const help = document.querySelector(`#${params.slug}-help-offcanvas`);
    help && help.classList.remove('open');
  }

  displayHelpPage(id) {
    this.pageTargets.forEach(elm => {
      elm.classList.remove('d-block');
      elm.classList.add('d-none');
    });

    const pageBody = this.pageTargets.find(elm => elm.id === `${id}-page-body`);
    pageBody && pageBody.classList.remove('d-none');
    pageBody && pageBody.classList.add('d-block');
  }

  updateHelpBody(tourKey) {
    const tipButton = document.querySelector('.show-onboarding-tip-button');
    if (tipButton){
      tipButton.dataset.tourKey = tourKey
    }
  }
}
