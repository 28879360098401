const initializeCharacterTracking = () => {
  $('body').on('keyup', 'input[data-max], textarea[data-max]', function () {
    const max = $(this).data('max');
    const counter = $(this).data('counterId');

    $(`#${counter}`).text(max - $(this).val().length);
  });

  $("input[data-max], textarea[data-max]").trigger('keyup');
}

module.exports = initializeCharacterTracking