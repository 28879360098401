import { Controller } from "@hotwired/stimulus"

let debounce = undefined;

export default class extends Controller {
  static targets = [ "currentAthleteCheck", "label", "athleteForm", "nonathleteForm", "firstName", "lastName", "existingAthletes" ]
  static values = {
    url: String
  }

  connect() {
    // console.log("Hello, Athlete Images Controller here!", this.element)
    // console.log(this.postTargets)
    // console.log(this.addButtonTarget)
    // console.log(this.containerTarget)
  }

  makeSelection(event) {
    const currentAthlete = event.target.id === 'current-athlete'

    this.labelTargets.forEach(elm => elm.classList.remove('bg-fanword-yellow'));

    const label = this.labelTargets.find(elm => elm.getAttribute('for') == event.target.id)
    label && label.classList.add('bg-fanword-yellow')

    if (currentAthlete) {
      this.athleteFormTarget.classList.remove('d-none');
      this.athleteFormTarget.classList.add('d-block');
      this.nonathleteFormTarget.classList.remove('d-block');
      this.nonathleteFormTarget.classList.add('d-none');
    } else {
      this.athleteFormTarget.classList.remove('d-block');
      this.athleteFormTarget.classList.add('d-none');
      this.nonathleteFormTarget.classList.remove('d-none');
      this.nonathleteFormTarget.classList.add('d-block');
    }
  }

  checkPreexisting(event) {
    // console.log('Checking for pre-existing athlete');

    const first_name = this.firstNameTarget.value;
    const last_name = this.lastNameTarget.value;

    if (first_name.length === 0 || last_name.length === 0) {
      // console.log('Not enough name data to check, exiting');

      // hide the existing athlete list
      this.existingAthletesTarget.classList.remove('d-block');
      this.existingAthletesTarget.classList.add('d-none');

      return;
    }

    if (debounce) {
      clearTimeout(debounce);
    }

    debounce = setTimeout(() => {
      fetch(`/athletes/preexisting_check.json`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        body: JSON.stringify({ first_name: this.firstNameTarget.value, last_name: this.lastNameTarget.value })
      })
      .then(response => response.json())
      .then(data => {
        // console.log('Got this data back from the prexisting check:', data);

        if (data.length > 0) {

          let markup = '<ul class="ps-3 ps-sm-auto">';
          data.forEach(athlete => {
            markup += `<li class='mb-3 mb-sm-auto'><a href='${this.urlValue}athletes/${athlete[1]}' target='_blank'>${this.urlValue}athletes/${athlete[1]}</a></li>`;
          });
          markup += '</ul>';

          this.existingAthletesTarget.innerHTML += markup;
          this.existingAthletesTarget.classList.remove('d-none');
          this.existingAthletesTarget.classList.add('d-block');
        } else {
          this.existingAthletesTarget.classList.remove('d-block');
          this.existingAthletesTarget.classList.add('d-none');
        }
      });
    }, 1000);

  }

}
