import { Controller } from "@hotwired/stimulus"
import 'select2';

var selected_data = {};

export default class extends Controller {
  static targets = [ "type", "id" ]
  static values = {
    endpoint: String
  }

  connect() {
    // console.log("Hello, Admin Reviewable Controller here!", this.element);
    // console.log(this.idTarget);
    // console.log(this.typeTarget);

    selected_data = { id: this.idTarget.dataset['selectedId'], text: this.idTarget.dataset['selectedText'] };

    this.triggerTypeChange();
  }

  updateIds(event) {
    var $value = event.target.value;

    if (this.idTarget.classList.contains("select2-hidden-accessible")) {
        this.idTarget.value = null
        this.triggerIdChange();
        $(this.idTarget).select2('destroy');
    }

    $(this.idTarget).select2({
        data: [selected_data],
        ajax: {
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))
            },
            url: `/admin/${this.endpointValue}/polymorphic_searchable/`,
            data: function (params) {
                return {
                    searchable_type: $value,
                    search: params.term
                };
            }
        }
    });

    if (selected_data.id) {
        this.idTarget.value = selected_data.id;
        this.triggerIdChange();
        selected_data = {};
    }
  }

  triggerIdChange() {
    this.idTarget.dispatchEvent(new Event("change"));
  }

  triggerTypeChange() {
    this.typeTarget.dispatchEvent(new Event("change"));
  }

}
