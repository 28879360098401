import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input', 'modal' ]

  connect() {
    // console.log("Hello, XML Help Controller from Stimulus!", this.element)
    // console.log(this.inputTarget);
    // console.log(this.containerTarget)
    // console.log(this.addButtonTarget)
  }

  openFinder() {
    this.inputTarget.click();
  }

  findXmlIds() {
    const file = this.inputTarget.files[0];

    var reader = new FileReader();

    reader.onload = (e) => {
      this.searchForIds(reader.result);
    }

    reader.readAsText(file);
  }

  searchForIds(xml) {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml,"text/xml");

    const ids = Array.from(xmlDoc.getElementsByTagName("team")).map(team => team['id'].trim());
    // console.log('Found IDS', ids);

    document.querySelector('#found_xml_id_1_input').value = ids[0];
    document.querySelector('#found_xml_id_1_label').innerText = ids[0];
    document.querySelector('#found_xml_id_2_input').value = ids[1];
    document.querySelector('#found_xml_id_2_label').innerText = ids[1];

    new bootstrap.Modal(this.modalTarget).show();
  }
}

