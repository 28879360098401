import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { createAlert } from '../packs/createAlert';

export default class extends Controller {
  static targets = [ "questionsContainer", "recipientsContainer", "recipient", "question", "sortable", "addRecipientSaveButton" ]
  static values = {
    template: Number,
  }

  connect() {
    // console.log("Hello, Stories Interview Template Controller here!", this.element)
    // console.log(this.questionsContainerTarget)
    // console.log(this.questionTargets)
    // console.log(this.addRecipientSaveButtonTarget)

    this.sortableTargets.forEach(elm => {
      Sortable.create(elm, {
        draggable: '.question-sortable',
        dataIdAttr: 'data-sortable-id',
        handle: ".sortable-handle",  // Drag handle selector within list items

        // Changed sorting within list
        onUpdate: (event) => {
          const rows = event.target.querySelectorAll('.row.question-sortable');
          const attachmentIds = Array.from(rows).map(elm => elm.dataset.attachmentId)

          const token = $('meta[name="csrf-token"]').attr('content');
          $.ajaxSetup({
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', token);
            }
          });

          $.post(`/interview_templates/${this.templateValue}/reorder`, { ordered_question_ids: attachmentIds }).then(() => {
            createAlert('success', 'Question order updated')
          })
        }
      });
    })
  }

  addQuestion() {
    fetch(`/interview_templates/${this.templateValue}/questions/new`).then(res => res.text()).then(partial => {
      // partial comes with no index field, need to dynamically update it
      partial = partial.replace(/NEW_ID/g, this.questionTargets.length + 1)

      this.questionsContainerTarget.insertAdjacentHTML( 'beforeend', partial );
    });
  }

  addRecipient() {
    fetch(`/interview_templates/${this.templateValue}/recipients/new`).then(res => res.text()).then(partial => {
      // partial has 3 form fields, need to replace it globally
      partial = partial.replace(/NEW_ID/g, this.recipientTargets.length + 1)

      this.recipientsContainerTarget.insertAdjacentHTML( 'beforeend', partial );

      if (this.recipientTargets.length > 0) {
        // console.log('> 0')
        this.addRecipientSaveButtonTarget.classList.remove("d-none");
        this.addRecipientSaveButtonTarget.classList.add("d-block");
      } else {
        // console.log('else')
        this.addRecipientSaveButtonTarget.classList.remove("d-block");
        this.addRecipientSaveButtonTarget.classList.add("d-none");
      }
    });
  }

}
