import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "editTextArea", "approval", "approvalForm", "submitButton", "pendingChange" ]

  // internal state for if there is a pending change to either sub-forms
  editDirty = false;
  commentDirty = false;

  originalEditValue = '';

  connect() {
    // console.log("Hello, Athlete Degrees Controller here!", this.element)
    // console.log(this.containerTarget);
    this.approvalTargets.forEach(elm => elm && elm.addEventListener('change', this.submitApprovalForm.bind(this)))

    // store the original value so we can see if its dirty later
    this.originalEditValue = this.editTextAreaTarget.value;

    this.increaseTextAreaSize();
  }

  increaseTextAreaSize() {
    this.editTextAreaTarget.style.height = (40 + this.editTextAreaTarget.scrollHeight) + "px";
  }

  submitApprovalForm(event) {
    this.approvalFormTarget.submit();
  }

  handleCommentChange(event) {
    // console.log('Handle comment change', event.target.value);

    if(event.target.value.length > 0) {
      this.commentDirty = true;
    } else {
      this.commentDirty = false;
    }

    this.updateSubmitDisabledState();
  }

  handleEditChange(event) {
    // console.log('Handle edit change, comparing original edit to current value');
    // console.log(event.target.value === this.originalEditValue);

    if(event.target.value !== this.originalEditValue) {
      this.editDirty = true;
    } else {
      this.editDirty = false;
    }

    this.updateSubmitDisabledState();
  }

  updateSubmitDisabledState() {
    if (this.editDirty || this.commentDirty) {
      this.submitButtonTarget.setAttribute('disabled', true);

      this.pendingChangeTarget.classList.remove('d-none');
      this.pendingChangeTarget.classList.add('d-block');
    } else {
      this.submitButtonTarget.removeAttribute('disabled');

      this.pendingChangeTarget.classList.remove('d-block');
      this.pendingChangeTarget.classList.add('d-none');
    }
  }

}
