import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "accolade", "container", "addButton" ]

  connect() {
    // console.log("Hello, Stimulus!", this.element)
    // console.log(this.accoladeTargets)
    // console.log(this.containerTarget)
    // console.log(this.addButtonTarget)
  }

  addAccolade(event) {
    fetch('/accolades').then(res => res.text()).then(partial => {
      this.containerTarget.insertAdjacentHTML( 'beforeend', partial );
    });
  }
}

