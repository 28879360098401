import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    // console.log("Hello, Athlete Basic Info controller here!", this.element)
  }

  clearDateOfBirth(event) {
    event.preventDefault();

    const dobElements = document.querySelectorAll('select[id^="athlete_date_of_birth"]');
    dobElements.forEach(elm => elm.value = "");
  }

}
