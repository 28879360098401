import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import { createAlert } from '../packs/createAlert';

export default class extends Controller {
  static targets = [ "container", "saveButton", "question", "sortable" ]
  static values = {
    interview: Number,
    story: Number
  }

  connect() {
    // console.log("Hello, Stories Interview Controller here!", this.element)
    // console.log(this.containerTarget)
    // console.log(this.saveButtonValue)
    // console.log(this.questionValue)

    this.sortableTargets.forEach(elm => {
      Sortable.create(elm, {
        draggable: '.question-sortable',
        dataIdAttr: 'data-sortable-id',
        handle: ".sortable-handle",  // Drag handle selector within list items

        // Changed sorting within list
        onUpdate: (event) => {
          const rows = event.target.querySelectorAll('.row.question-sortable');
          const attachmentIds = Array.from(rows).map(elm => elm.dataset.attachmentId)

          const token = $('meta[name="csrf-token"]').attr('content');
          $.ajaxSetup({
            beforeSend: function (xhr) {
              xhr.setRequestHeader('X-CSRF-Token', token);
            }
          });

          $.post(`/stories/${this.storyValue}/interviews/${this.interviewValue}/questions/reorder`, { ordered_question_ids: attachmentIds }).then(() => {
            createAlert('success', 'Question order updated')
          })
        }
      });
    })
  }

  addQuestion(event) {
    if (event.target.classList.contains('disabled')) {
      return;
    }

    fetch(`/stories/${this.storyValue}/interviews/${this.interviewValue}/questions/new`).then(res => res.text()).then(partial => {
      this.containerTarget.insertAdjacentHTML( 'beforeend', partial );

      // add focus to newly created input
      this.questionTargets[this.questionTargets.length - 1].querySelector('input').focus();

      if (this.questionTargets.length >= 3) {
        this.saveButtonTarget.removeAttribute('disabled');
      }
    });
  }
}
