import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'email', 'phone' ]

  connect() {
    // console.log("Hello, from Feature Story Contact controller", this.element)
    // console.log(this.emailTarget)
    // console.log(this.phoneTarget)
    this.determineRequiredStatus();
  }

  determineRequiredStatus() {
    if (this.emailTarget.value === "" && this.phoneTarget.value === "") {
      this.emailTarget.setAttribute('required', true);
      this.phoneTarget.setAttribute('required', true);
    } else if (this.emailTarget.value !== "") {
      this.emailTarget.setAttribute('required', true);
      this.phoneTarget.removeAttribute('required');
    } else if (this.phoneTarget.value !== "") {
      this.phoneTarget.setAttribute('required', true);
      this.emailTarget.removeAttribute('required');
    }
  }

  updateRequiredFields({ params }) {
    if (params.target === "email") {
      this.emailTarget.setAttribute('required', true);
      this.phoneTarget.removeAttribute('required');
    } else if (params.target === "phone") {
      this.emailTarget.removeAttribute('required');
      this.phoneTarget.setAttribute('required', true);
    } else {
      this.emailTarget.setAttribute('required', true);
      this.phoneTarget.setAttribute('required', true);
    }
  }

}

