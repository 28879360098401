import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button", "input" ]

  connect() {
    // console.log("Hello, Stories AI Search Controller here!", this.element);
    // console.log(this.buttonTargets);
    // console.log(this.inputTargets);
  }

  async toggleInput(event) {
    if(this.inputTarget.classList.contains('d-none')) {
      this.inputTarget.classList.remove('d-none');
      this.inputTarget.classList.add('d-inline');
      this.inputTarget.focus();
    } else {
      this.inputTarget.classList.remove('d-inline');
      this.inputTarget.classList.add('d-none');
    }
  }

}
