import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "modal", "form", "modalHeader", "submitButton" ]

  connect() {
    // console.log("Hello, Add Link Controller here!", this.element)

    this.modalTarget.addEventListener('show.bs.modal', event => {
      const button = event.relatedTarget;
      const linkType = button && button.getAttribute('data-bs-link-type');

      if (linkType) {
        // update the modal header
        this.modalHeaderTarget.innerText = `Add ${linkType.charAt(0).toUpperCase() + linkType.slice(1)}`;

        // set the submit butto to the correct form
        this.submitButtonTarget.setAttribute('form', `${linkType}-form`);

        // if we have a linkType, reset all forms to hidden
        this.formTargets.forEach(elm => {
          elm.classList.remove('d-block');
          elm.classList.add('d-none');
        })

        // and enable the necessary form
        const form = this.formTargets.find(elm => elm.id == `add_${linkType}`);
        form.classList.remove('d-none');
        form.classList.add('d-block');
      }
    });
  }

}
