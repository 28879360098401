import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "button" ]
  static values = {
    athleteId: String
  }

  connect() {
    // console.log("Hello, Athlete Analytics Controller here!", this.element);
    // console.log(this.buttonTargets);
    // console.log(this.athleteIdValue);
  }

  async updateRange(event) {
    const range = event.params.range;
    const athlete_id = this.athleteIdValue;

    const params = new URLSearchParams();
    params.set('chart[athlete_id]', athlete_id)
    params.set('chart[range]', range)

    const data = await $.get(`/analytics/visits-chart?${params.toString()}`)

    const chart = Chartkick.charts['athlete-analytics-visits-chart'];
    chart.updateData(data);

    this.buttonTargets.forEach((button) => {
      button.classList.remove('color-fanword-black');
    });

    event.target.classList.add('color-fanword-black');
  }

}
