import { Controller } from "@hotwired/stimulus"
import { createAlert } from "../packs/createAlert"
export default class extends Controller {
  static targets = [ "modal", "promoteButton", "recipientCheckbox", "sendNotificationButton", "viewButton", "notificationRecipients" ]
  static values = {
    template: String,
    recipient: String,
    story: String
  }

  connect() {
    // console.log("Hello, Stories Interview Template Responses Controller here!", this.element)
    // console.log(this.modalTarget);
    // console.log(this.templateValue);
  }

  displayResults({ params }) {
    // console.log('Params', event.params.recipient);
    // console.log('Template value', this.templateValue);
    // console.log('Modal', this.modalTarget);
    // console.log('promoted?', params.promoted);

    if (params.story) {
      this.promoteButtonTarget.classList.remove('d-block');
      this.promoteButtonTarget.classList.add('d-none');

      this.viewButtonTarget.classList.remove('d-none');
      this.viewButtonTarget.classList.add('d-block');

      this.storyValue = params.story;
    } else {
      this.promoteButtonTarget.classList.remove('d-none');
      this.promoteButtonTarget.classList.add('d-block');

      this.viewButtonTarget.classList.remove('d-block');
      this.viewButtonTarget.classList.add('d-none');

      this.storyValue = null;
    }

    fetch(`/interview_templates/${this.templateValue}/recipients/${params.recipient}/responses`).then(res => res.text()).then(partial => {
      this.modalTarget.innerHTML = "";
      this.modalTarget.insertAdjacentHTML( 'beforeend', partial );

      this.recipientValue = params.recipient;
    });
  }

  toggleAudio({ params }) {
    const audio = document.getElementById(`audio-${params.id}`);
    audio.addEventListener('ended', function changeToPlayAtEnd() {
      const button = document.getElementById(`audio-button-${params.id}`);
      button.innerHTML = '<i class="fas fa-play-circle fa-fw"></i>'

      this.removeEventListener('ended', changeToPlayAtEnd);
    }, { once: true });

    if (audio.paused) {
      audio.play();
      const button = document.getElementById(`audio-button-${params.id}`);
      button.innerHTML = '<i class="fas fa-pause fa-fw"></i>'
    } else {
      audio.pause();
      const button = document.getElementById(`audio-button-${params.id}`);
      button.innerHTML = '<i class="fas fa-play-circle fa-fw"></i>'
    }
  }

  promoteToStory() {
    fetch(`/interview_templates/${this.templateValue}/recipients/${this.recipientValue}/promote`, { method: 'post' })
      .then((response) => {
        return response.json();
      })
      .then((data) => {

        createAlert('info', 'Story created successfully.');

        this.storyValue = data.story_id;

        this.promoteButtonTarget.classList.add('d-none');

        this.viewButtonTarget.classList.remove('d-none');
        this.viewButtonTarget.classList.add('d-block');
      });
  }

  toggleAllRecipients(event) {
    if (event.target.checked === false) {
      this.recipientCheckboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = false;
      });
      this.notificationRecipientsTarget.value = JSON.stringify([]);
      this.sendNotificationButtonTarget.setAttribute('disabled', true);
    } else {
      this.recipientCheckboxTargets.filter((checkbox) => !checkbox.disabled).forEach((checkbox) => {
        checkbox.checked = true;
      });
      this.notificationRecipientsTarget.value = JSON.stringify(this.recipientCheckboxTargets.filter((checkbox) => !checkbox.disabled).map((checkbox) => parseInt(checkbox.value)));
      this.sendNotificationButtonTarget.removeAttribute('disabled');
    }
  }

  toggleRecipient({ params }) {
    let recipients = JSON.parse(this.notificationRecipientsTarget.value);
    if (recipients.includes(params.recipient)) {
      recipients = recipients.filter((recipient) => recipient !== params.recipient);
    } else {
      recipients.push(params.recipient);
    }
    this.notificationRecipientsTarget.value = JSON.stringify(recipients);

    if (recipients.length > 0) {
      this.sendNotificationButtonTarget.removeAttribute('disabled');
    } else {
      this.sendNotificationButtonTarget.setAttribute('disabled', true);
    }
  }

  viewStory() {
    window.location = `/entity_admins/stories_ai/${this.storyValue}`;
  }

}
