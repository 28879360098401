import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['contact', 'availability']

  static values = {
    option: String
  }

  connect() {
    // console.log("Hello, Stimulus!", this.element)
    // console.log(this.contactTarget)
    console.log(this.optionValue)

    if (this.optionValue) {
      this.toggleElements(this.optionValue)
    }
  }

  changeOption(event) {
    this.toggleElements(event.target.value);
  }

  toggleElements(elements) {
     if (elements == "contact") {
      this.availabilityTarget.classList.remove('d-block');
      this.availabilityTarget.classList.add('d-none');

      this.contactTarget.classList.remove('d-none');
      this.contactTarget.classList.add('d-block');
    } else if (elements == "availability") {
      this.contactTarget.classList.remove('d-block');
      this.contactTarget.classList.add('d-none');

      this.availabilityTarget.classList.remove('d-none');
      this.availabilityTarget.classList.add('d-block');
    }
  }


}