// src/controllers/athlete_social_post_controller.js
import { Controller } from "@hotwired/stimulus"
import html2canvas from 'html2canvas';

let currentTemplate = null;

export default class extends Controller {
  static targets = [ 'controls', 'container', 'downloadButton' ]

  connect() {
    // console.log("Hello, Stimulus from social image creator controller", this.element)
    // console.log(this.imageTarget)
    // console.log(this.imageContainerTarget)
    // console.log(this.headerTarget)
    // console.log(this.subheaderTarget)
    // console.log(this.schoolLogoContainerTarget)
    // console.log(this.schoolLogoTarget)
  }

  async selectTemplate(event) {

    const template_id = event.target.value;

    if (!template_id) {
      this.downloadButtonTarget.classList.remove('d-block');
      this.downloadButtonTarget.classList.add('d-none');

      this.controlsTarget.innerHTML = '';
      this.containerTarget.innerHTML = '';
      return
    };

    currentTemplate = event.target.selectedOptions[0].innerText.trim();

    fetch(`/stories/graphics/${template_id}/controls`).then(res => res.text()).then(controls => {
      this.controlsTarget.innerHTML = '';
      this.controlsTarget.insertAdjacentHTML( 'beforeend', controls );
    });

    fetch(`/stories/graphics/${template_id}/template`).then(res => res.text()).then(template => {
      this.containerTarget.innerHTML = '';
      this.containerTarget.insertAdjacentHTML( 'beforeend', template );
    });

    this.downloadButtonTarget.classList.remove('d-none');
    this.downloadButtonTarget.classList.add('d-block');
  }

  async convertImgToBase64URL(url) {
    return fetch('/admin/base64?url=' + url).then(resp => resp.text()).then( text => {
      return text
    });
  }

  previewImage(event) {
    if (event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {

        // remove the placeholder src
        this.imageTarget.src = e.target.result
      };

      reader.readAsDataURL(event.detail.content.files[0]);
    }
  }

  updateElement(event) {

    // This chunk deals with image uploads
    if (event.detail && event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {

        // set the preview as the url
        document.querySelector(`#${event.detail.content.dataset.propertyId}`).src = e.target.result;
        // this.previewTarget.src = e.target.result;
      };

      reader.readAsDataURL(event.detail.content.files[0]);

      return;
    }

    // this chunk deals with text and color changes
    const id = event.params.controlId;
    const property = event.params.property;
    const value = event.target.value;

    console.log(id, property, value);

    switch(property) {
      case 'color':
        document.querySelector(`#${id}`).style.color = value;
        break;

      case 'text':
        document.querySelector(`#${id}`).innerHTML = value;
        break;

      case 'background-color':
        document.querySelector(`#${id}`).style.backgroundColor = value;
        break;
    }
  }

  downloadImage(event) {
    event.preventDefault();

    html2canvas(document.querySelector('#fw-container'), {
      // allowTaint: true,
      // foreignObjectRendering: true,
      logging: true,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      scale: 1
    })
    .then((canvas) => {
      const url = canvas.toDataURL('image/png');
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = `${currentTemplate}.png`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

}

