import { Controller } from "@hotwired/stimulus"
import { createAlert } from '../packs/createAlert';
import WaveSurfer from 'wavesurfer.js'
import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'

let wavesurfer;
let scrollingWaveform = true;

export default class extends Controller {
  static targets = [ 'input', 'recordButton', 'stopButton', 'removeButton', 'transcribingNotice', 'playButton', 'pauseButton', 'waveFormContainer', 'audioFile' ]
  static values = {
    story: Number,
    interview: Number,
    question: Number
  }

  connect() {
    // console.log("Hello, Story Admin Question Recorder", this.element)
    // console.log(this.storyValue, this.interviewValue, this.questionValue);
    // console.log(this.availabilityTargets)
    // console.log(this.requestIdValue)
    this.record = null;

    this.initAudio();
  }

  initAudio() {
    //check if browser supports getUserMedia
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert('Your browser does not support audio recording!');
      return;
    }

    this.createWaveSurfer();
  }

  createWaveSurfer() {
    // Create an instance of WaveSurfer
    if (wavesurfer) {
      wavesurfer.destroy()
    }

    wavesurfer = WaveSurfer.create({
      container: '#waveFormContainer',
      waveColor: '#0e1a2c',
      progressColor: '#FFD81B',
      // Set a bar width
      barWidth: 2,
      // Optionally, specify the spacing between bars
      barGap: 1,
      // And the bar radius
      barRadius: 2
    })

    // Initialize the Record plugin
    this.record = wavesurfer.registerPlugin(RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: false }))
    // Render recorded audio
    this.record.on('record-end', async (blob) => {

      // remove the old audio file if its present
      if (this.hasAudioFileTarget) {
        this.audioFileTarget.remove();
      }

      // create file...
      let file = new File([blob], "audio-question.wav", { lastModified: new Date().getTime() });

      // ... to post to the server
      const formData = new FormData();
      formData.append('audioFile', file);

      fetch(`/stories/${this.storyValue}/interviews/${this.interviewValue}/questions/${this.questionValue}/add_audio`, {
        method: 'post',
        body: formData,
        accept: 'application/json'
      })
      .then(response => response.json())
      .then(data =>{
        // ... and attach to the DOM
        const audioElement = document.createElement('audio');
        audioElement.src = URL.createObjectURL(file);
        audioElement.controls = false;
        audioElement.dataset['storyAdminQuestionRecorderTarget'] = 'audioFile';
        audioElement.classList.add('d-none');
        this.element.appendChild(audioElement);

        // hide the transcribing notice
        this.transcribingNoticeTarget.classList.remove('d-block');
        this.transcribingNoticeTarget.classList.add('d-none');

        // display the remove button
        this.removeButtonTarget.classList.remove('d-none');
        this.removeButtonTarget.classList.add('d-block');

        this.playButtonTarget.classList.remove('d-none');
        this.playButtonTarget.classList.add('d-block');

        this.inputTarget.classList.add('ps-5');
        this.inputTarget.value = data.text;

        createAlert('success', 'Audio file uploaded successfully')
      }).catch(error => {
        console.log("Error uploading audio", error);
      });

    })

  }

  recordAudio() {
    this.record.startRecording().then(() => {
      // hide the record button
      this.recordButtonTarget.classList.remove('d-block');
      this.recordButtonTarget.classList.add('d-none');

      // display the stop button
      this.stopButtonTarget.classList.remove('d-none');
      this.stopButtonTarget.classList.add('d-block');
    })
  }

  stopAudio() {
    if (this.record.isRecording() || this.record.isPaused()) {
      this.record.stopRecording()
    }

    // hide the stop button
    this.stopButtonTarget.classList.remove('d-block');
    this.stopButtonTarget.classList.add('d-none');

    // display the transcribing notice
    this.transcribingNoticeTarget.classList.remove('d-none');
    this.transcribingNoticeTarget.classList.add('d-block');
  }

  removeAudio() {

    fetch(`/stories/${this.storyValue}/interviews/${this.interviewValue}/questions/${this.questionValue}/remove_audio`, {
      method: 'delete',
      accept: 'application/json'
    }).then( () => {
      // hide the remove button
      this.removeButtonTarget.classList.remove('d-block');
      this.removeButtonTarget.classList.add('d-none');

      // remove the play button
      this.playButtonTarget.classList.remove('d-block');
      this.playButtonTarget.classList.add('d-none');

      // remove the pause button
      this.pauseButtonTarget.classList.remove('d-block');
      this.pauseButtonTarget.classList.add('d-none');

      // display the record button
      this.recordButtonTarget.classList.remove('d-none');
      this.recordButtonTarget.classList.add('d-block');

      // remove the audio file
      this.audioFileTarget.remove();

      this.inputTarget.classList.remove('ps-5')

      createAlert('success', 'Audio file removed')
    }).catch(error => {
      console.log("Error removing audio", error);
    });

  }

  playAudio() {
    this.playButtonTarget.classList.remove('d-block');
    this.playButtonTarget.classList.add('d-none');

    this.pauseButtonTarget.classList.remove('d-none');
    this.pauseButtonTarget.classList.add('d-block');

    this.audioFileTarget.addEventListener('ended', () => {
      this.pauseButtonTarget.classList.remove('d-block');
      this.pauseButtonTarget.classList.add('d-none');

      this.playButtonTarget.classList.remove('d-none');
      this.playButtonTarget.classList.add('d-block');
    });

    this.audioFileTarget.play();
  }

  pauseAudio() {
    this.pauseButtonTarget.classList.remove('d-block');
    this.pauseButtonTarget.classList.add('d-none');

    this.playButtonTarget.classList.remove('d-none');
    this.playButtonTarget.classList.add('d-block');

    this.audioFileTarget.pause();
  }

}
