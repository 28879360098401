import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'gameFileButton', 'gameFileInput', 'form', 'interviewFileInput', 'interviewFileButton', 'factContainer', 'fact' ]
  static values = { 
    teamId: Number,
    gameId: Number
  }

  connect() {
    // console.log("Hello, Game Recap Controller here!", this.element)
    // console.log(this.inputTarget)
  }

  uploadGameFile() {
    this.gameFileInputTarget.click();
  }

  updateGameFileName() {
    this.gameFileButtonTarget.innerText = this.gameFileInputTarget.files[0].name;
  }

  uploadInterviewFile() {
    this.interviewFileInputTarget.click();
  }

  updateInterviewFileName() {
    this.interviewFileButtonTarget.innerText = this.interviewFileInputTarget.files[0].name;
  }

  addFact(event) {
    event.preventDefault();

    fetch(`/entity/teams/${this.teamIdValue}/games/${this.gameIdValue}/game_facts/new`).then(res => res.text()).then(partial => {
      partial = partial.replace(/NEW_ID/g, this.factTargets.length + 1)

      this.factContainerTarget.insertAdjacentHTML( 'beforeend', partial );

      // add focus to newly created input
      this.factTargets[this.factTargets.length - 1].querySelector('input').focus();
    });
  }
}
