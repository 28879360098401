import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []
  static values = {
    targetType: String,
    targetId: Number
  }

  connect() {
    // console.log("Hello, Story Question Sets here!", this.element)
    // console.log(this.targetTypeValue);
    // console.log(this.targetIdValue);
  }

  selectQuestionSet(event) {
    const question_set_id = event.params.id;

    // console.log('Question Set ID', question_set_id);
    // console.log('Target Type', this.targetTypeValue);
    // console.log('Target ID', this.targetIdValue);

    const headers = {
      'Content-Type': 'application/json'
    }

    const csrf = document.querySelector('meta[name="csrf-token"]')
    if (csrf) {
      headers['X-CSRF-Token'] = csrf.content
    }

    fetch('/stories/question_sets', {
      method: 'POST',
      headers,
      body: JSON.stringify({
        question_set_id: question_set_id,
        target_type: this.targetTypeValue,
        target_id: this.targetIdValue
      })
    }).catch(error => {
      console.error('Got an error when assigning question set questions!', error);
    }).then( () => {
      const url = new URL(location.href);
      url.searchParams.set('tab', 'questions');
      location.assign(url.search);

      window.location.reload();
    })
  }

}
