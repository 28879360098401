import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container", "list" ]
  static values = {
    sport: String,
    existing: Array
  }

  connect() {
    // console.log("Hello, Sport Details controller here!", this.element)
    // console.log(this.existingValue);
    // console.log(this.sportValue);
    // console.log(this.containerTarget);

    if (this.sportValue) {
      this.displayDetails(this.sportValue)
    }
  }

  checkForSportDetails(event) {
    event.preventDefault();

    const sport_id = event.target.value;
    this.displayDetails(sport_id);
  }

  displayDetails(sport_id) {
    fetch(`/sports/${sport_id}/details`).then(res => res.json()).then(data => {
      if (data.length > 0) {
        this.listTarget.innerHTML = "";

        this.containerTarget.classList.remove('d-none');
        this.containerTarget.classList.add('d-block');

        data.forEach(detail => {
          this.listTarget.innerHTML = this.listTarget.innerHTML + `<div class="form-check mb-3"><input type="checkbox" class="form-checkbox me-2" name="sport_details[]" value='${detail.id}' ${this.existingValue.includes(detail.id) ? "checked" : ""} /><label class="me-3">${detail.text}</label></div>`;
        })
      } else {
        this.listTarget.innerHTML = "";

        this.containerTarget.classList.remove('d-block');
        this.containerTarget.classList.add('d-none');
      }
    });
  }

}
