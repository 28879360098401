import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "content", "link" ]

  connect() {
    console.log("Hello, Help Page Controller here!", this.element)
    console.log(this.pageTargets)
  }

  loadPage(event) {
    console.log('loadPage', event.params);
    const url = `/help_pages/${parseInt(event.params.id, 10)}`;
    this.displayHelpPage(url);

    this.linkTargets.forEach(link => link.classList.remove('active'));
    event.target.classList.add('active');
  }

  displayHelpPage(url) {
    console.log('Attempting to load page', url);

    fetch(url).then(response => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error('Network response was not ok.');
      }
    }).then(html => {
      this.contentTarget.innerHTML = html;
    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    })
  }

}
