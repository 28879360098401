import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("Hello, Stimulus!", this.element)
    // console.log(this.accoladeTargets)
    // console.log(this.containerTarget)
    // console.log(this.addButtonTarget)

    this.element.addEventListener('shown.bs.tab', function (event) {
      // update the query parameter in the url on navigation
      const bsTarget = event.target.dataset.bsTarget;
      var tab = bsTarget.replace('#', '');

      // Construct URLSearchParams object instance from current URL querystring.
      var queryParams = new URLSearchParams(window.location.search);

      // Set new or modify existing parameter value.
      queryParams.set("tab", tab);

      // Replace current querystring with the new one.
      history.replaceState(null, null, "?"+queryParams.toString());

      // if any element inside of this tab is marked as needing to load content, reload content
      if (document.querySelectorAll(`${event.target.dataset.bsTarget} *[reloadcontent="true"]`).length > 0) {
        window.location.reload();
      }
    })
  }

}
