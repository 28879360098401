// src/controllers/athlete_social_post_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "video", "container", "addButton" ]

  connect() {
    // console.log("Hello, Stimulus!", this.element)
    // console.log(this.postTargets)
    // console.log(this.addButtonTarget)
    // console.log(this.containerTarget)
  }

  addVideo(event) {
    fetch('/athlete_videos').then(res => res.text()).then(partial => {
      this.containerTarget.insertAdjacentHTML( 'beforeend', partial );

      if (this.videoTargets.length >= 3) {
       this.addButtonTarget.setAttribute('disabled', true);
      }
    });
  }
}

