import { Controller } from "@hotwired/stimulus"

require("activeadmin_quill_editor")

export default class extends Controller {
  static targets = [ "content", "imagePreview", "textarea" ]
  static values = {}

  connect() {
    // console.log("Hello, Story Publish FanWord Controller here!", this.element)

    if (this.hasContentTarget) {
      const quill = new Quill(this.contentTarget, {
        theme: 'snow',
        modules: {
          toolbar: true,
        },
      });

      quill.on('text-change', (delta, oldDelta, source) => {
        this.textareaTarget.value = quill.container.firstChild.innerHTML
      })
    }

  }

  previewImage(event) {
    if (event.detail.content.files && event.detail.content.files[0]) {
      var reader = new FileReader();
      reader.onload = (e) => {

        // set the preview as the url
        this.imagePreviewTarget.src = e.target.result;
      };

      reader.readAsDataURL(event.detail.content.files[0]);
    }
  }

}
